import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiFolderAlert } from "@mdi/js";
import config from "../../../utils/settings";
import "../../../assets/styles/header.css";

const DigitalRepository = () => {
  const { t } = useTranslation();

  return (
    <Button
      id="button-go-to-dr"
      href={`${config.REACT_APP_DR_BASE_URL}/home`}
      color="primary"
      startIcon={<Icon path={mdiFolderAlert} size={1} />}
      className="button-go-to-dr"
    >
      <span className="button-go-to-dr-label">{t("digitalRepository")}</span>
    </Button>
  );
};
export default DigitalRepository;
